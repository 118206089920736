import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";

import { MatDialog } from "@angular/material/dialog";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Order, OrderStepName } from "@gul-si/commons";
import { OrderService } from "../../services/order.rest.service";
import { NotificationService } from "../../services/notification.service";
import { AuthGuard } from "../../component/login/auth.guard";
import { ButtonsModule } from "../../component/material/buttons/buttons.module";
import { QuoteComponent } from "./steps/quote/quote.component";
import { EditComponent } from "./steps/edit/edit.component";
import { StepperComponent } from "../../component/material/stepper/stepper.component";
import { NgIf } from "@angular/common";
import { ResumeComponent } from "./steps/resume/resume.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    QuoteComponent,
    ButtonsModule,
    EditComponent,
    ResumeComponent,
    StepperComponent,
    FontAwesomeModule,
    RouterLink,
  ],
})
export class OrderPageComponent implements OnInit {
  faHome = faHome;

  isEditable: boolean = false;
  OrderStepName = OrderStepName;
  disableAction = true;
  id: string = "";
  order: Order = new Order();

  constructor(
    private route: Router,
    protected authService: AuthGuard,
    public dialog: MatDialog,
    protected orderService: OrderService,
    public activatedRoute: ActivatedRoute,
    public notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params) => {
        this.id = params["orderId"];
        this.orderService
          .getById(this.id)
          .subscribe((res: { data: Order[] }) => {
            return (this.order = new Order().fromObject(
              res.data.pop() as Order,
            ));
          });
      },
      (error) => {
        this.notification.error("ERREUR", error);
      },
    );
  }

  async createQuoteAndNext(): Promise<void> {
    if (this.order._id)
      await this.orderService.createQuote(this.order._id).then(async () => {
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
        await this.nextOrderStep();
      });
  }
  nextOrderStep(): void {
    this.orderService
      .nextStep(this.order._id)
      .subscribe((page: { data: Partial<any>[] }) => {
        this.order = new Order().fromObject(page.data[0]);
      });
  }

  previousOrderStep(): void {
    this.orderService
      .previousStep(this.order._id)
      .subscribe((page: { data: Partial<any>[] }) => {
        this.order = new Order().fromObject(page.data[0]);
      });
  }

  changeOwner(userId: string): void {
    if (this.order._id)
      this.orderService
        .updateField(this.order._id, ["user", userId], [])
        .subscribe((page: { data: Partial<any>[] }) => {
          this.order = new Order().fromObject(page.data[0]);
        });
  }
}
