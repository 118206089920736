import { Component, HostListener, Inject } from "@angular/core";
import { BasicFormComponent } from "../basic-form/basic-form.component";
import { Image } from "@gul-si/commons";
import { NotificationService } from "../../../../services/notification.service";
import { ProductService } from "../../../../services/product.rest.service";
import { FormBuilder } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { ButtonsModule } from "../../buttons/buttons.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-upload-image",
  standalone: true,
  imports: [ButtonsModule, MatDialogModule, FontAwesomeModule],
  templateUrl: "./upload-image.component.html",
  styleUrl: "./upload-image.component.scss",
})
export class UploadImageComponent extends BasicFormComponent<Image> {
  faUpload = faUpload;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public override data: {
      object: Image;
    },
    private catalogue: ProductService,
    private notification: NotificationService,
    protected fb: FormBuilder,
    public override dialogRef: MatDialogRef<any>,
  ) {
    super(data, fb, dialogRef);
  }
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.currentTarget as HTMLElement).classList.add("dragover");
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.currentTarget as HTMLElement).classList.remove("dragover");
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.currentTarget as HTMLElement).classList.remove("dragover");

    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      this.handleFile(event.dataTransfer.files[0], 0);
      event.dataTransfer.clearData();
    }
  }

  @HostListener("window:paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    const items = event.clipboardData?.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.indexOf("image") !== -1) {
          const file = item.getAsFile();
          if (file) {
            this.handleFile(file, 0);
          }
        }
      }
    }
  }

  onFileSelected(event: Event, index: string | number) {
    const file: File = (event as any).target.files[0];
    this.handleFile(file, index);
  }

  handleFile(file: File, index: string | number) {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const upload$ = this.catalogue.uploadImage(
        this.data.object.url,
        this.data.object.rank,
        formData,
      );
      upload$.subscribe(
        (e) => {
          if (this.dialogRef)
            return this.dialogRef.close(e.data[0].images[Number(index)]);
        },
        (error) => {
          this.notification.error("ERROR", error);
        },
      );
    }
  }
}
