<div class="screen-margin">
  <div
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <div style="display: flex; flex-direction: row; align-items: center">
      <div class="button" [routerLink]="['/']">
        <fa-icon [icon]="faHome"></fa-icon>
      </div>
      <h4>Clients</h4>
    </div>
    <div class="button" (click)="iportClientsCSV()">
      <fa-icon [icon]="faUpload"></fa-icon>
    </div>
  </div>
  <div class="action-panel">
    <p>Entrer un email non utilisé pour créer un client</p>
    <div>
      <app-default-input
        [input]="newUserFormControl"
        [placeholder]="'example@email.com'"
        [required]="false"
      >
      </app-default-input>
      <app-cta-button [text]="'Créer'" (click)="createUser()"></app-cta-button>
    </div>
  </div>
  <app-list [service]="userService" [template]="promotionCard"></app-list>

  <ng-template #promotionCard let-content="content">
    <div class="row" [routerLink]="['/clients', content?._id, 'edit']">
      <p>{{ content?._id }}:</p>
      <h4>{{ content?.email }}</h4>
      <h3>{{ content?.firstName }} {{ content?.lastName }}</h3>
    </div>
  </ng-template>
</div>
