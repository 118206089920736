import { DatePipe, DecimalPipe, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { Order } from "@gul-si/commons";

@Component({
  selector: "app-order-card",
  standalone: true,
  imports: [NgIf, DecimalPipe, RouterLink, DatePipe],
  templateUrl: "./order-card.component.html",
  styleUrl: "./order-card.component.scss",
})
export class OrderCardComponent {
  @Input() order: Order = new Order();

  hasPictureOnFirstArticle(order: Order) {
    if (
      order.clientOrder.articles[0] &&
      order.clientOrder.articles[0].product.images[0]
    )
      return true;
    return false;
  }
}
