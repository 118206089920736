import { Component, Inject, Input } from "@angular/core";
import { BasicFormComponent } from "../basic-form/basic-form.component";
import { FormBuilder } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { ButtonsModule } from "../../buttons/buttons.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-upload-document",
  standalone: true,
  imports: [ButtonsModule, MatDialogModule, FontAwesomeModule],
  templateUrl: "./upload-document.component.html",
  styleUrl: "./upload-document.component.scss",
})
export class UploadDocumentComponent extends BasicFormComponent<any> {
  faUpload = faUpload;
  @Input() handleFile!: (file: File) => void;

  constructor(
    @Inject(MAT_DIALOG_DATA) public override data: { object: any },
    protected fb: FormBuilder,
    public override dialogRef: MatDialogRef<any>,
  ) {
    super({ object: {} }, fb, dialogRef);
    this.handleFile = data.object;
  }
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.currentTarget as HTMLElement).classList.add("dragover");
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.currentTarget as HTMLElement).classList.remove("dragover");
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.currentTarget as HTMLElement).classList.remove("dragover");

    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      this.handleFile(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  }

  onFileSelected(event: Event) {
    const file: File = (event as any).target.files[0];
    return this.dialogRef.close(file);
  }
}
