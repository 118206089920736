import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import {
  faChevronLeft,
  faChevronRight,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FilterBuilder, TreeNode } from "@gul-si/commons";
import { Tree } from "@gul-si/commons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgIf, NgClass, NgFor } from "@angular/common";
import { CtaButtonComponent } from "../../component/material/buttons/cta-button/cta-button.component";
import { NodeService, TreeService } from "../../services/tree.rest.service";
import { TreeComponent } from "../../component/material/tree/tree.component";
import { ResultListAsListComponent } from "../../component/material/result-list-as-list/result-list-as-list.component";
import { TreeNodeFormComponent } from "../../component/material/form/tree-node-form/tree-node-form.component";
import { CategoryService } from "../../services/category.rest.service";
import { NotificationService } from "../../services/notification.service";
import { ProductService } from "../../services/product.rest.service";
import { SearchBarComponent } from "../../component/material/search-bar/search-bar.component";

@Component({
  selector: "app-product-inventory",
  templateUrl: "./product-inventory.component.html",
  styleUrls: ["./product-inventory.component.scss"],
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    TreeComponent,
    NgClass,
    FontAwesomeModule,
    CtaButtonComponent,
    NgFor,
    ResultListAsListComponent,
    SearchBarComponent,
  ],
})
export class ProductInventoryComponent implements OnInit {
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faHome = faHome;

  constructor(
    public route: Router,
    public dialog: MatDialog,
    public treeService: TreeService,
    public categoryService: CategoryService,
    public activatedRoute: ActivatedRoute,
    public nodeService: NodeService,
    public notification: NotificationService,
    public catalogue: ProductService,
  ) {}

  tree: Tree = new Tree();
  isTreeOpen = false;
  selected: TreeNode | undefined;
  filter: FilterBuilder = new FilterBuilder();
  selectedTab: string | undefined;
  tabs = ["Produits", "Bundle"];

  ngOnInit(): void {
    this.treeService.get(0, 10).subscribe(
      (res) => {
        //TODO clean this shit
        this.tree = new Tree().fromObject(res.data[0]);
        this.activatedRoute.queryParams.subscribe(
          (params) => {
            let node = new TreeNode("");
            this.tree.root.visit((e) => {
              if (e._id === params["node"]) node = e;
            });
            if (!params["node"]) this.selectAllProduct();
            else this.selectNode(node);
          },
          (error) => {
            this.notification.error("ERREUR", error);
          },
        );
      },
      (error) => {
        this.notification.error("ERROR", error);
      },
    );
  }

  toggleTree() {
    this.isTreeOpen = !this.isTreeOpen;
  }
  saveNode(node: TreeNode) {
    this.nodeService.update(node._id, node).subscribe(
      (res: { data: Tree[] }) => {
        //TODO clean this shit
        this.tree = new Tree().fromObject(res.data[0]);
        this.selected = node;
        this.notification.succes("OK", "Nouvelle catégorie sauvegardée");
      },
      (error: any) => {
        this.notification.error("ERROR", error);
      },
    );
  }

  editNode() {
    const dialogRef = this.dialog.open(TreeNodeFormComponent, {
      data: {
        object: this.selected,
      },
      panelClass: "custom-dialog",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.saveNode(result);
    });
  }

  selectNode(node: TreeNode) {
    if (!node) return;
    this.selected = node;
    if (this.selected._id === this.tree.root._id) this.toggleTree();
    if (this.selected.childs.length === 0) this.toggleTree();
    this.filter = new FilterBuilder().fieldEquals("associatedNode", node._id);
    this.addQueryParam(node._id);
  }

  selectAllProduct() {
    this.selected = new TreeNode("");
    this.filter = new FilterBuilder();
    this.toggleTree();
  }

  addSubNode(node: TreeNode) {
    const id = node._id;
    if (node.childs.length === 0) {
      this.catalogue.updateAssociatedNode(id, this.tree.root._id).subscribe(
        () => {
          this.notification.succes("OK", "Produits déplacés dans /");
        },
        (error: any) => {
          this.notification.error("ERROR", error);
        },
      );
    }

    this.nodeService.add(id).subscribe(
      (res: { data: Tree[] }) => {
        this.tree = new Tree().fromObject(res.data[0]);
        this.notification.succes("OK", "Noeud ajouté");
      },
      (error: any) => {
        this.notification.error("ERROR", error);
      },
    );
  }

  deleteNode(node: TreeNode) {
    const id = node._id;
    this.catalogue.updateAssociatedNode(id, this.tree.root._id).subscribe(
      () => {
        this.nodeService.delete(id).subscribe(
          (res: { data: Tree[] }) => {
            this.tree = new Tree().fromObject(res.data[0]);
            this.notification.succes("OK", "Noeud supprimé");
          },
          (error: any) => {
            this.notification.error("ERROR", error);
          },
        );

        this.notification.succes("OK", "Produits déplacés dans /");
      },
      (error: any) => {
        this.notification.error("ERROR", error);
      },
    );
  }

  associateNode(ids: Event) {
    // to Implement
    if (this.selected)
      this.catalogue
        .setAssociatedNode(ids as unknown as string[], this.selected._id)
        .subscribe(() => {});
  }

  leaves() {
    let leaves: any[] = [];
    if (this.tree !== undefined) {
      this.tree.visit((node) => {
        if (node.childs.length === 0) leaves.push(node);
      });
    }
    leaves = leaves.map((leaf: { _id: any; label: any }) => {
      return { id: leaf._id, name: leaf.label };
    });
    return leaves.sort((a: { name: string }, b: { name: any }) =>
      a.name.localeCompare(b.name),
    );
  }

  create() {
    let nodeId = this.tree.root._id;
    if (this.selected) nodeId = this.selected._id;
    this.catalogue
      .createAndAssociate(nodeId)
      .subscribe((res: { data: { _id: any }[] }) =>
        this.route.navigate(["/produit", res.data[0]._id, "edit"]),
      );
  }

  addQueryParam(nodeId: string) {
    this.route.navigate([], {
      queryParams: { node: nodeId },
      queryParamsHandling: "merge", // pour conserver les autres query params
    });
  }
}
