<div [routerLink]="['/commande', order._id]">
  <div class="topic">
    <div class="column">
      <span>N°{{ order._id }}</span>
      <h4 *ngIf="order.clientOrder.articles.length">
        Du
        {{ order.clientOrder.articles[0].startDate?.date | date: "d/MM/yy" }}
        au
        {{ order.clientOrder.articles[0].startDate?.date | date: "d/MM/yy" }}
      </h4>
      <h5>{{ order.customer.email }}</h5>
      <h5>{{ order.customer.firstName }} {{ order.customer.lastName }}</h5>
    </div>
    <div class="column align-text-right">
      <h2>{{ order?.totalPrice()?.getTTC() | number: "1.2-2" }} €</h2>
      <span class="status">{{
        order?.currentStep ? order.currentStep : "Prise en compte"
      }}</span>
    </div>
  </div>
  <div class="description">
    <img
      *ngIf="hasPictureOnFirstArticle(order)"
      class="picture"
      src="{{ order.clientOrder.articles[0].product.images[0].url }}"
    />
    <img
      *ngIf="!hasPictureOnFirstArticle(order)"
      class="picture"
      src="'/assets/no-image.png'"
    />
    <div class="column">
      <!-- <h3 class=""> {{order?.clientOrder?.product?.name}}</h3> -->
      <span class="">+{{ order.totalCount() - 1 }} autres produits</span>
    </div>
  </div>
</div>
