import { Component, OnInit } from "@angular/core";
import { Order } from "@gul-si/commons";
import { concat } from "lodash";
import { DatePipe, DecimalPipe, NgFor, NgIf } from "@angular/common";
import { NotificationService } from "../../../services/notification.service";
import { RouterLink } from "@angular/router";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { OrderService } from "../../../services/order.rest.service";

@Component({
  selector: "app-order-list",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    DecimalPipe,
    DatePipe,
    RouterLink,
    InfiniteScrollDirective,
    NgFor,
  ],
})
export class OrderListComponent implements OnInit {
  orders: Order[] = [];
  total = -1;
  page = 0;
  size = 10;
  throttle = 10;
  scrollDistance = 1;
  scrollUpDistance = 2;

  constructor(
    protected notification: NotificationService,
    private orderService: OrderService,
  ) {}

  ngOnInit(): void {
    this.page = 0;
    this.size = 10;
    this.orderService.get(this.page, this.size).subscribe(
      (next) => {
        this.orders = next.data.map((o) => {
          return new Order().fromObject(o);
        });
        this.total = next.length;
      },
      (error) => {
        this.notification.error("ERROR filters", error);
      },
    );
  }

  onScrollDown(ev?: any) {
    if (this.total > 0 && this.page + 1 * this.size < this.total) {
      this.page += 1;
      this.orderService.get(this.page, this.size).subscribe(
        (next) => {
          this.orders = concat(
            this.orders,
            next.data.map((o) => {
              return new Order().fromObject(o);
            }),
          );
        },
        (error) => {
          this.notification.error("ERROR", error);
        },
      );
    }
  }

  hasPictureOnFirstArticle(order: Order) {
    if (
      order.clientOrder.articles[0] &&
      order.clientOrder.articles[0].product.images[0]
    )
      return true;
    return false;
  }
}
