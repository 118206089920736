<div class="screen-margin">
  <div style="display: flex; flex-direction: row; align-items: center">
    <div class="button" [routerLink]="['/clients']">
      <fa-icon [icon]="faHome"></fa-icon>
    </div>
    <h4>Clients</h4>
  </div>

  <app-customer-picker
    [disabled]="false"
    [customer]="customer"
    (emitSave)="onSaveUser($event)"
  ></app-customer-picker>

  <app-cta-button
    [text]="'créer une commande'"
    (click)="createOrder()"
    class="create-order-button"
  ></app-cta-button>

  <div
    class=""
    infinite-scroll
    [infiniteScrollDistance]="orderService.scrollDistance"
    [infiniteScrollUpDistance]="orderService.scrollUpDistance"
    [infiniteScrollThrottle]="orderService.throttle"
    (scrolled)="nextOrderPage()"
  >
    <ng-container *ngFor="let item of orderService.items">
      <ng-container>
        <app-order-card [order]="item"></app-order-card>
      </ng-container>
    </ng-container>
  </div>
</div>
