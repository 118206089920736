<div class="screen-margin">
  <div style="display: flex; flex-direction: row; align-items: center">
    <div class="button" [routerLink]="['/']">
      <fa-icon [icon]="faHome"></fa-icon>
    </div>

    <h4>Commandes</h4>
  </div>

  <app-order-list></app-order-list>
</div>
