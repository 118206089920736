import { Component, Input } from "@angular/core";
import { ResultListComponent } from "../result-list/result-list.component";
import { RouterLink } from "@angular/router";
import { faEdit, faRocket } from "@fortawesome/free-solid-svg-icons";
import { PriceComponent } from "../price/price.component";
import { NgFor, NgIf } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ToggleCtaButtonComponent } from "../buttons/toggle-cta-button/toggle-cta-button.component";
import { ProductService } from "../../../services/product.rest.service";
import { SelectComponent } from "../select/select.component";
import { Product } from "@gul-si/commons";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";

@Component({
  selector: "app-result-list-as-list",
  templateUrl: "./result-list-as-list.component.html",
  styleUrls: ["./result-list-as-list.component.scss"],
  standalone: true,
  imports: [
    InfiniteScrollDirective,
    ToggleCtaButtonComponent,
    SelectComponent,
    FontAwesomeModule,
    NgFor,
    NgIf,
    PriceComponent,
    RouterLink,
  ],
})
export class ResultListAsListComponent extends ResultListComponent {
  faRocket = faRocket;
  faEdit = faEdit;

  action!: { id: string; name: string };
  selected: string[] = [];
  @Input() moveActionTarget: { id: string; name: string }[] = [];

  override ngOnChanges(change: any): void {
    if (change.filter) {
      this.selected = [];
    }
    super.ngOnChanges(change);
  }
  select(id: string | undefined) {
    const findIndex = this.selected.findIndex((e) => e === id);
    if (findIndex !== -1) {
      this.selected.splice(findIndex, 1);
    } else if (id) {
      this.selected.push(id);
    }
  }

  isSelected(id: string | undefined) {
    const findIndex = this.selected.findIndex((e) => e === id);
    return findIndex !== -1;
  }

  selectAll() {
    this.selected = this.array.map((e) => {
      if (e._id) return e._id;
      else return "";
    });
  }
  unselectAll() {
    this.selected = [];
  }

  selectAction(action: string) {
    const foundAction = this.moveActionTarget.find((e) => e.name === action);
    if (foundAction) this.action = foundAction;
  }

  moveActionNames() {
    return this.moveActionTarget;
  }
  performAction() {
    (this.client as ProductService)
      .setAssociatedNode(this.selected, this.action.id)
      .subscribe(
        (next) => {
          this.notification.succes("SUSSES", "");
          this.array = this.array.filter(
            (e) => !this.selected.find((s) => s === e._id),
          );
          this.selected = [];
        },
        (error) => {
          this.notification.error("ERROR filters", error);
        },
      );
  }

  hasPicture(product: Product) {
    if (product.images.length > 0) return true;
    return false;
  }
}
