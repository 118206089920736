<div class="header">
  <h3>Mise en ligne d'un document</h3>
  <app-minus-button
    id="cancel"
    class="close"
    [mat-dialog-close]
  ></app-minus-button>
</div>
<mat-dialog-content>
  <div class="wrapper">
    <div
      class="file-input"
      (dragover)="onDragOver($event)"
      (drop)="onDrop($event)"
      (dragleave)="onDragLeave($event)"
    >
      <input
        type="file"
        id="file"
        class="file"
        (change)="onFileSelected($event)"
      />
      <label for="file">
        <fa-icon [icon]="faUpload"></fa-icon>
        <p>Glissez-déposez ou collez votre image ici</p>
      </label>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions> </mat-dialog-actions>
